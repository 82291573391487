import React, { useEffect, useState, useContext } from "react";
import MetaMaskSDK from "@metamask/sdk";

import {
  Navbar,
  NavbarBrand,
  Button,
  NavLink,
  NavItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import web3service from "../../services/web3service";
import P2PBackendService from "../../services/p2papiservice";
import { useNavigate } from "react-router-dom";
import { User } from "../../context/context";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../../css/navbar.css";
import { toast } from "react-toastify";
import detectEthereumProvider from "@metamask/detect-provider";
import Toast from "../../components/Toast";

import { Box, Stack } from "@mui/material";

//navbar component
const AppNavbar = () => {
  const { user, setUser } = useContext(User);
  const [navbar, setNavbar] = useState(false);
  const { isLogged, setIsLogged } = useContext(User);
  const { setModalShow } = useContext(User);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  const [toast, setToast] = useState({ message: "", severity: "success" });

  const locate = useLocation();
  let navigate = useNavigate();

  new MetaMaskSDK({
    useDeeplink: false,
    communicationLayerPreference: "socket",
  });

  useEffect(() => {
    const detectMetamask = async () => {
      const provider = await detectEthereumProvider();
      if (provider) {
        setIsMetamaskInstalled(true);
      }
    };
    detectMetamask();
  }, []);

  const styles = {
    // navbar: {
    //   paddingLeft: "24px",
    //   paddingRight: "100px",
    // },
    brand: {
      color: "#000000",
      fontSize: "40px",
      fontFamily: "fantasy",
      marginRight: "4rem",
    },
    navLink: {
      listStyleType: "none",
      color: "white",
      gap: "1rem",
    },
    navItem: {
      listStyleType: "none",
      color: "white",
    },
    uncontrolledDropdown: {
      listStyleType: "none",
      display: "block",
      marginRight: "8rem",
    },
    dropDownToggle: {
      color: "white",
      fontSize: "20px",
    },
    styleNone: {
      listStyleType: "none",
    },
  };
  //change background color of the navbar on scroll
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  //go to register screen on click to register button
  let handleRegister = async () => {
    await web3service.getAccount();
    // window ethereum is loaded lazily in mobile browsers.
    // eth_requestAccounts RPC API triggeres this injection.

    if ((await web3service.detectChain()) !== true) {
      await web3service.addChain();
      await web3service.switchChain();
    }
    navigate("/register", { replace: true });
  };
  //scroll method to gotop2p section
  const gotop2p = () => {
    window.scrollTo({
      top: 800,
      behavior: "smooth",
    });
  };
  //scroll method to our techonology section
  const ourtech = () => {
    window.scrollTo({
      top: 2100,
      behavior: "smooth",
    });
  };
  //scroll method to about us section
  const aboutus = () => {
    window.scrollTo({
      top: 4320,
      behavior: "smooth",
    });
  };
  //navigate to marketplace screen
  const marketplace = () => {
    navigate("/marketplace", { replace: true });
  };
  //handle the click to signin method
  const SignIn = async () => {
    await web3service.getAccount();
    // window ethereum is loaded lazily in mobile browsers.
    // eth_requestAccounts RPC API triggeres this injection.

    if ((await web3service.detectChain()) !== true) {
      await web3service.addChain();
      await web3service.switchChain();
    }

    var myHeaders = new Headers();
    const accounts = await web3service.getAccount();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("publicAddress", accounts);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      keepalive: true,
      redirect: "follow",
    };

    try {
      P2PBackendService.signIn(requestOptions)
        .then((response) => {
          if (response === "error") {
            setToast({
              message: "Your information could not be located. Please complete the registration process to proceed.",
              severity: "error",
            });
            throw new Error("User Not Found");
          } else if (response.ok) {
            return response.json();
          }
        })
        .then((res) => {
          const dat = {
            id: res.data.id,
            firstname: res.data.firstname,
            lastname: res.data.lastname,
            email: res.data.email,
          };
          if (res.code === 200) {
            localStorage.setItem("user", JSON.stringify(dat));
            localStorage.setItem("isLogged", "true");
            localStorage.setItem("publicAddress", accounts);
            setUser(dat);
            setIsLogged(true);
            navigate("/", { replace: true });
          } else {
            // alert("We didnt find any records. Kindly Register");
            setToast({
              message: "Your information could not be located. Please contact support.",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
  //log out the current user
  const logout = () => {
    localStorage.clear();
    navigate("/", { replace: true });
    setUser(null);
    setIsLogged(false);
  };

  const consuming = () => navigate("/addconsuming", { replace: true });
  const producing = () => navigate("/addproducing", { replace: true });
  const simulation = () => navigate("/simulation", { replace: true });

  window.addEventListener("scroll", changeBackground);
  return (
    <Navbar
      className={navbar ? "appnavbar active" : "appnavbar"}
      bg="dark"
      variant="dark"
      fixed="top"
      style={styles.navbar}
    >
      {/* Toast message */}
      <Toast
        message={toast.message}
        severity={toast.severity}
        open={Boolean(toast.message)}
        handleClose={() =>
          setToast((p) => ({ message: "", severity: p.severity }))
        }
      />
      {
        <Stack
          direction={{ xs: "column", md: "row" }}
          flexWrap="wrap"
          alignItems={"center"}
          justifyContent={"space-between"}
          width="100%"
        >
          <NavbarBrand
            className="brand"
            tag={Link}
            to={"/"}
            style={styles.brand}
          >
            {navbar ? (
              <img
                className="logo-blue"
                src="/images/p2p.png"
                width={150}
                height={50}
                alt="not found"
              />
            ) : (
              <img
                className="logo-white"
                src="/images/logo-p2p-white.png"
                width={150}
                height={50}
                alt="not found"
              />
            )}
          </NavbarBrand>
          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            flexWrap="wrap"
          >
            <NavItem style={styles.navItem}>
              <NavLink
                style={styles.navLink}
                href="#p2pconnect"
                onClick={gotop2p}
              >
                P2PCONNECT
              </NavLink>
            </NavItem>
            <NavItem style={styles.navItem}>
              <NavLink style={styles.navLink} href="#ourtech" onClick={ourtech}>
                OUR TECHNOLOGY
              </NavLink>
            </NavItem>
            <NavItem style={styles.navItem}>
              <NavLink
                style={styles.navLink}
                href="#about us"
                onClick={aboutus}
              >
                ABOUT US
              </NavLink>
            </NavItem>
          </Stack>
          <Box>
            <Button className="btnit" onClick={SignIn}>
              Sign in
            </Button>
            <Button className="btnit" onClick={handleRegister}>
              Register
            </Button>
          </Box>
          {/* {isLogged &&
          (locate.pathname == "/" ||
            locate.pathname == "/addconsuming" ||
            locate.pathname == "/addproducing" ||
            locate.pathname == "/simulation") ? (
            <NavItem style={styles.navItem}>
              <NavLink style={styles.navLink} href="/marketplace">
                {" "}
                MARKETPLACE
              </NavLink>
            </NavItem>
          ) : (
            <></>
          )} */}
        </Stack>
      }

      {/* <div className="float-left mr-3">
        {isLogged ? (
          <UncontrolledButtonDropdown
            style={styles.uncontrolledDropdown}
            nav
            inNavbar
          >
            <DropdownToggle nav caret style={styles.dropDownToggle}>
              {user.firstname}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink style={styles.styleNone} onClick={simulation}>
                  Simulation
                </NavLink>
              </DropdownItem>

              <DropdownItem>
                <NavLink style={styles.styleNone} onClick={consuming}>
                  Add Consuming Station
                </NavLink>
              </DropdownItem>

              <DropdownItem>
                <NavLink style={styles.styleNone} onClick={producing}>
                  Add producing Station
                </NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink style={styles.styleNone} onClick={marketplace}>
                  Marketplace
                </NavLink>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <NavLink style={styles.styleNone} onClick={logout}>
                  Logout
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        ) : (
          <>
            <Button className="btnit" onClick={SignIn}>
              Sign in
            </Button>
            <Button className="btnit" onClick={handleRegister}>
              Register
            </Button>
          </>
        )}
      </div> */}
    </Navbar>
  );
};

export default AppNavbar;
